.buttton{
    padding: 12px 24px;
    font-size: 1rem;
    color: #fff;
    font-weight: 600;
    background-color: #c61612;
    border-radius: 4px;
    border: 1px solid #b7120f;
    cursor: pointer;
}
.buttton:hover{
    background: #b7120f;
}
.hero{
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
}
.hero::before{
    content: '';
    background: url("../../pic/zz.jpg") no-repeat center center/cover;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.hero .content{
    height: 120vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1300px;
    margin: auto;
    margin-top: -1.5cm;
    align-items: center;
}

.hero .content p:first-child{
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-shadow: black 0.03em 0.03em 0.12em;
    align-items: center;
    justify-content: center;
}
.hero .buttton{
    margin: 1rem 0;
    height: 70px;
    border-radius: 4px;
    background-color: transparent;
    border: 4px solid #c61612;
}
.hero .buttton:hover{
    border: 4px solid #c61612;
    background-color: #c61612;
    transition: 2s;
}
@media screen and (max-width : 940px){
    .hero .content p:nth-child(3){
        font-size: 2.6rem;
    }
    .hero .content p:nth-child(4){
        font-size: 1.2rem;
    }
}
.expaand{
    color: #fff;
    font-size: 4rem;
}