.grid{
    flex: 7;
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    grid-gap: 30px;
    align-items: center;
}
.grid > article{
    background: #fcfcfc;
    border: none;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    text-align: center;
    align-items: center;
    width: 310px;
    height: 325px;
    transition: ease-in-out .3s ;
    cursor: zoom-in;
    border-radius: 10px;
}
.grid > article:hover{
    transform: translateY(5px);
    
    -ms-transform: scale(1.00001); /* IE 9 */
    box-shadow: rgba(17, 17, 26, 0.05) 3px 3px 3px, rgba(17, 17, 26, 0.1) 3px 3px 11px;
    -webkit-transform: scale(1.03); /* Safari 3-8 */
    transform: scale(1.03);
    cursor: zoom-in;
}
.grid > article img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    opacity: .8;
}
.text{
    padding: 0 20px 20px;
}
.text h4{
    font-size: 20px;
    margin-top: -.2cm;
    color: #545757;
    cursor: pointer;
    font-family :'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}
.text h4:hover{
    color: rgba(198,22,18,255);
}
@media(max-width : 768px){
    .grid{
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width : 500px){
    .grid{
        grid-template-columns: repeat(1,1fr);
    }
    .grid > article{
        text-align: center;
    }
}
#btn{
    border-radius: 20%;
    margin-top: .35cm;
}