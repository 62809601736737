.hero3{
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    height: 70vh;
    width: 100%;
    position: relative;
}
.hero3::before{
    content: '';
    background: url("../../pic/gg.jpg") no-repeat center center/cover;
    height: 70vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.hero3 .content3{
    height: 120vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1300px;
    margin: auto;
    margin-top: -1.5cm;
    align-items: center;
}

.hero3 .content3 p:first-child{
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-shadow: black 0.03em 0.03em 0.12em;
    align-items: center;
    justify-content: center;
}



*, ::before, ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    background: #fff;
  }
  
  .contaainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 980px;
    height: 500px;
    background: #f1f1f1;
    margin: 100px auto 0;
    word-break: break-all;
    border: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .bloc-tabs {
    display: flex;
  }
  .tabs {
  
    padding: 15px;
    text-align: center;
    width: 50%;
    background: rgba(128, 128, 128, 0.075);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
  }
  .tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .active-tabs  {
    background: white;
    border-bottom: 1px solid transparent;
  }
  
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: #c61612;
  }
  
  
  
  button {
    border: none;
    
  }
  .conteent-tabs {
    flex-grow : 1;
  }
  .conteent {
    background: white;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
  }
  .conteent h2 {
    padding: 0px 0 5px 0px;
  }
  .conteent hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }
  .conteent p {
    width: 100%;
    height: 100%;
  }
  .active-conteent {
    display: block;
  }

  .coont{
      max-width: 84%;
      margin: auto;
  }
  .flx{
      display: flex;
      justify-content: space-between;
  }
  ul{
      list-style: none;
  }
  a{
      text-decoration: none;
  }
  .grrid{
      display: grid;
      grid-template-columns: repeat(3,1fr);
      grid-gap: 30px; 
  }
  .branding{
      background-color: #353538;
      color: #fff;
      margin-top: 35px;
      padding: 80px 0;
      width: 90%;
      margin-left: 2cm;
      height: 40vh;
  }
  .branding h1{
      font-size: 40px;
      margin-top: -1.2cm;
      color: #c61612;
      margin-right: 20px;
  }
  .branding h2{
      font-weight: 500;
      font-style: 22px;
      margin: 10px 10px 30px 40px;
      font-size: 22px;
  }
  .branding p{
      color: #fff;
      opacity: .7;
      margin: 10px 10px 60px 40px;
  }
  .heading1 p{
      margin-left: -.5cm;
      color: gray;
  }
  .crd{
      text-align: center;
      padding: 50px 0;
  }
  .crd .bxx{
      text-align: left;
  }
  .crd  img{
      width: 100%;
      height: 100%;
      object-fit: contain;
  }
  .crd .txtt{
      background-color: #e9eff2;
      box-shadow: 0 5px 20px 0 rgba(0 0 0 /6%);
      padding: 20px;
      margin: 15px;
      margin-top: -30px;
      position: relative;
      z-index: 1;
  }
  .crd .txtt h2{
    color: #c61612;
  }
  .crd .txtt p{
      color: #353538;
  }

  .brandingg{
    background-color: #353538;
    color: #fff;
    margin-top: 35px;
    padding: 80px 0;
    width: 95%;
    margin-left: 1cm;
    height: 40vh;
}
.brandingg h1{
  font-size: 40px;
  margin-top: -1.2cm;
  color: #c61612;
  margin-right: 20px;
}
.brandingg h2{
  font-weight: 500;
  font-style: 22px;
  margin: 10px 10px 30px 40px;
  font-size: 22px;
}
.brandingg p{
  color: #fff;
  opacity: .7;
  margin: 10px 10px 60px 40px;
}

.brandinggg{
  background-color: #353538;
  color: #fff;
  margin-top: 35px;
  padding: 80px 0;
  width: 96.4%;
  margin-left: 1cm;
  height: 40vh;
}
.brandinggg h1{
font-size: 40px;
margin-top: -1.2cm;
color: #c61612;
margin-right: 20px;
}
.brandinggg h2{
font-weight: 500;
font-style: 22px;
margin: 10px 10px 30px 40px;
font-size: 22px;
}
.brandinggg p{
color: #fff;
opacity: .7;
margin: 10px 10px 60px 40px;
}

.brandingggg{
  background-color: #353538;
  color: #fff;
  margin-top: 35px;
  padding: 80px 0;
  width: 97%;
  margin-left: .6cm;
  height: 40vh;
}
.brandingggg h1{
font-size: 40px;
margin-top: -1.2cm;
color: #c61612;
margin-right: 20px;
}
.brandingggg h2{
font-weight: 500;
font-style: 22px;
margin: 10px 10px 30px 40px;
font-size: 22px;
}
.brandingggg p{
color: #fff;
opacity: .7;
margin: 10px 10px 60px 40px;
}

.brandinggggg{
  background-color: #353538;
  color: #fff;
  margin-top: 35px;
  padding: 80px 0;
  width: 93%;
  margin-left: 1.5cm;
  height: 40vh;
}
.brandinggggg h1{
font-size: 40px;
margin-top: -1.2cm;
color: #c61612;
margin-right: 20px;
}
.brandinggggg h2{
font-weight: 500;
font-style: 22px;
margin: 10px 10px 30px 40px;
font-size: 22px;
}
.brandinggggg p{
color: #fff;
opacity: .7;
margin: 10px 10px 60px 40px;
}