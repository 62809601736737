.gallery {
    margin-bottom: 50px;
  }
  .gallery img {
    transition: 0.5s;
    width: 100%;
    height: 100%;
  }
  .gallery .items {
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    height: 340px;
  }
  .gallery h3 {
    margin: 10px 0 10px 0;
    font-weight: 400;
  }
  .gallery h3:hover{
      color: #c61612;
  }
  .gallery .img {
    position: relative;
    overflow: hidden;
    width: 380px;
    height: 252px;
    border-radius: 8px;
  }
  
  .gallery .icone {
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 5;
    font-size: 40px;
    color: white;
    opacity: 0;
    transition: 0.5s ease-in-out;
  }
  .gallery .img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 380px;
    height: 252px;
    z-index: 2;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: 0.5s ease-in-out;
  }
  .hh{
    font-size: 18px;
    align-items: center;
    padding: 16px;
  }
  .hh:hover{
    color: #c61612;
  }
  .gallery .img::after {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    width: 360px;
    height: 232px;
    z-index: 3;
    border: 1px solid #a19e9e;
    opacity: 0;
    transition: 0.5s ease-in-out;
    border-radius: 8px;
  }
  
  .gallery .items:hover img {
    transform: scale(1.2);
    cursor: pointer;
  }
  .gallery .items:hover .icone,
  .gallery .items:hover .img::after,
  .gallery .items:hover .img::before {
    opacity: 1;
    cursor: pointer;
  }
  .popup {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 8;
  }
  
  .hide {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .popup-content {
    position: absolute;
    top: 30%;
    left: 20%;
    height: 60%;
    width: 50%;
  }
  .popup button {
    color: rgba(198,22,18,255);
    background: linear-gradient(145deg , #e2e8ec , #ffffff);
    box-shadow: 1px 1px 1px #cbcbcb , 1px -1px 1px #ffffff;
    border: none;
    outline: none;
    border-radius: 6px;
    transition: 0.3s all ease;
    padding: 20px 20px;
    color: #000;
    position: absolute;
    top: -7%;
    right: -34.5%;
    font-weight: bold;
  }
  .popup button:hover{
    background: rgb(201, 201, 201);
    color: black;
    transform: translateY(-2px);
  }
  @media screen and (max-width: 768px) {
    .gallery .container {
      max-width: 100%;
    }
    .gallery .img {
      width: 100%;
      height: 200px;
    }
  
    .gallery .img::after {
      width: 300px;
      height: 180px;
    }
    .popup-content {
      padding: 50px;
      position: absolute;
      top: 30%;
      left: 0%;
    }
    .popup button {
      background-color: #fff;
      padding: 20px 20px;
      color: #000;
      position: absolute;
      top: -2%;
      right: 6.5%;
      font-weight: bold;
    }
  }

  .heaa{
    text-align: center;
    padding: 2.5rem 0;
  }
  .heaa span{
    background: #e9eff2;
    color: #c61612;
    font-size: 2rem;
    border-radius: .5rem;
    padding: .2rem 1rem;
  }
  .heaa span.space{
    background: none;
  }
  
  .gall .box-cont{
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    margin-left: 1cm;
    margin-right: 1cm;
  }
  .gall .box-cont .boxxx{
    overflow: hidden;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
    border: 1rem solid #fff;
    border-radius: .5rem;
    flex: 1 1 24.3rem;
    height: 18rem;
    width: 19rem;
    position: relative;
  }
  .gall .box-cont .boxxx img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .gall .box-cont .boxxx .cnt{
    position: absolute;
    top: -100%;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    padding: 2rem;
    padding-top: 3rem;
    transition: .3s;
  }
  .gall .box-cont .boxxx .cnt h3{
    font-size: 1.6rem;
    color: #f12926;
  }
  .gall .box-cont .boxxx .cnt p{
    font-size: 0.89rem;
    color: #eee;
    padding: 1rem 0;
  }
  .gall .box-cont .boxxx:hover .cnt{
    top: 0;
  }
  .bttn{
    border: 1px solid #fff;
    background: none;
    padding: 10px 20px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    margin: 10px;
    transition: 0.4s;
    position: relative;
    overflow: hidden;
    transition: .3s;
    position: relative;
    overflow: hidden;
  }
  .btn1{
    color: #fff;
    border: 2px solid #c61612;
    background: #c61612;
  }
  .btn1:hover{
    color: #fff;
    background: transparent;
  }
  .bttn::before{
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0%;
    background: #c61612;
    z-index: -1;
    transition: .3s;
  }
  .btn1::before{
    top: 0;
    border-radius: 0 0 50% 50%;
  }
  .bttn:hover::before{
    height: 180%;
  }