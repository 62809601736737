.blog img {
    width: 100%;
    height: 250px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .blog .items {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    height: 500px;
  }
  .category span {
    font-size: 14px;
    color: grey;
    transition: 0.5s ease-in-out;
  }
  .category label {
    background-color: #c61612;
    font-size: 13px;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
  }
  .blog .items h4 {
    margin: 5px 0 10px 0;
    font-weight: 500;
    transition: 0.5s ease-in-out;
  }
  .blog .items p {
    font-size: 14px;
    color: grey;
    margin-bottom: 10px;
    transition: 0.5s ease-in-out;
  }
  .blog .items a {
    color: black;
    font-weight: 500;
    font-size: 14px;
    transition: 0.5s ease-in-out;
  }
  .blog .items a .ico {
    color: #c61612;
    transition: 0.5s ease-in-out;
  }
  .blog .items:hover {
    background-color: #c61612;
    cursor: pointer;
  }
  .blog .items:hover h4,
  .blog .items:hover span,
  .blog .items:hover a,
  .blog .items:hover a .ico,
  .blog .items:hover p {
    color: white;
  }
  .blog .items:hover label {
    background-color: #fff;
    color: #c61612;
    cursor: pointer;
  }
  /*---------blog single page------*/
  .category-list {
    color: black;
  }
  .category-list h1 {
    font-size: 30px;
  }
  .category-list hr {
    border: 0;
    border-top: 3px dashed #c61612;
    width: 260px;
    color: 0;
  }
  .category-list ul {
    margin-top: 20px;
  }
  .category-list ul li {
    margin-bottom: 15px;
    transition: 0.5s;
  }
  .category-list ul li:hover {
    color: #c61612;
    transform: translateX(10px);
    cursor: pointer;
  }
  .category-list .ico {
    color: #c61612;
    margin-right: 20px;
  }
  /*---------blog single page------*/