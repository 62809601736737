.body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Segoe UI';
    font-weight: 400;
    background-color: #ededed;
}
.footer{
    padding: 40px 0;
    background-color: #fff;
}
.footer .social{
    text-align: center;
    padding-bottom: 25px;
}
.footer .social .icone{
    color: rgb(90, 177, 206);
    font-size: 30px;
}
.footer .social a{
    font-size: 24px;
    color: inherit;
    border: 1px solid #ccc;
    width: 65px;
    height: 65px;
    line-height: 57px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    margin: 0 8px;
    opacity: 0.6;
}
.footer .social a:hover{
    opacity: 0.9;
}
.footer ul{
    margin-top: 0;
    padding: 0;
    list-style: none;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 0;
    text-align: center;
}
.footer ul a{
    color: inherit;
    text-decoration: none;
    opacity: 0.8;
}
.footer ul li{
    display: inline-block;
    padding: 0 15px;
}
.footer ul li:hover{
    opacity: 1;
    color: rgba(198,22,18,255);
}
.footer .copyright{
    margin-top: 15px;
    text-align: center;
    font-size: 13px;
    color: #aaa;
}