.header3{
    background-image: url('../../pic/j.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
    position: relative;
    background-size: cover;
    width:100%;
    height:400px;
    border-top-left-radius:50px; 
    border-top-right-radius:50px; 
    opacity:.9; 
    background-attachment:fixed
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins',sans-serif;
}
.sett{ 
    display: flex;
    justify-content: center;
    align-items: center;    
    min-height: 100vh;
    margin-top: -1.8cm;
}
.sett::before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
}
.cv{
    position: relative;
    min-width: 1100px;
    min-height: 550px;
    display: flex;
}
.cv .contactIn{
    position: absolute;
    top: 70px;
    width: 400px;
    height: calc(100% - 160px);
    background: #c62616;
    z-index: 1;
    padding: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 10px 30px 35px rgba(0, 0, 0, 0.15);
    left: -1cm;
    margin-top: -1cm;
}
.cv .contactIn h2{
    color: #000;
    font-size: 24px;
    font-weight: 500;
}
.cv .contactIn h2:after{
    content: '';
    position: absolute;
    left: 25%;
    top: 2.5cm;
    transform: translateX(-50%);
    height: 4px;
    width: 120px;
    border-radius: 2px;
    background-color: #fff;
}
.cv .contactIn .inf{
    position: relative;
    margin: 20px 0;
}
.cv .contactIn .inf .llli{
    position: relative;
    list-style: none;
    display: flex;
    margin: 20px 0;
    cursor: pointer;
    align-items: flex-start;
}
.cv .contactIn .inf .llli span:nth-child(1){
    width: 30px;
    min-width: 30px;
}
.cv .contactIn .inf .llli span:nth-child(1) img{
    max-width: 100%;
    filter: invert(1);
}
.cv .contactIn .inf .llli span:nth-child(2){
    color: #000;
    margin-left: 10px;
    font-weight: 300;
}

.cv .contactFoorm{
    position: absolute;
    padding: 70px 50px;
    padding-left: 250px;
    margin-left: 150px;
    width: calc(100% - 200px);
    height: 550px;
    background: #fff;
    box-shadow: 0 2px 50px 2px rgba(0, 0, 0, 0.25);
    margin-top: -1cm;
}
.cv .contactFoorm h2{
    color: #0f3959;
    font-size: 24px;
    font-weight: 500;
}
.cv .contactFoorm .formBxxx{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 30px;
}
.cv .contactFoorm .formBxxx .inputBxxx{
    position: relative;
    margin-bottom: 35px;
}
.cv .contactFoorm .formBxxx .inputBxxx.w50{
    width: 47%;
}
.cv .contactFoorm .formBxxx .inputBxxx.w100{
    width: 100%;
}
.cv .contactFoorm .formBxxx .inputBxxx input,
.cv .contactFoorm .formBxxx .inputBxxx textarea
{
    width: 100%;
    resize: none;
    padding: 5px 0;
    font-size: 15px;
    font-weight: 300;
    color: #333;
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 1px solid #c71612;
}
.cv .contactFoorm .formBxxx .inputBxxx textarea{
    height: 120px;
}
.cv .contactFoorm .formBxxx .inputBxxx span{
    position: absolute;
    left: 0;
    padding: 5px 0;
    pointer-events: none;
    font-size: 15px;
    font-weight: 300; 
    transition: .3s;
}
.cv .contactFoorm .formBxxx .inputBxxx input:focus ~ span,
.cv .contactFoorm .formBxxx .inputBxxx input:valid ~ span,
.cv .contactFoorm .formBxxx .inputBxxx textarea:focus ~ span,
.cv .contactFoorm .formBxxx .inputBxxx textarea:valid ~ span{
    transform: translateY(-22px);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #9e120c;
    font-weight: 500;
}
.cv .contactFoorm .formBxxx .inputBxxx input[type="submit"]{
    position: relative;
    cursor: pointer;
    background:#9e120c;
    color: #fff;
    border: none;
    max-width: 150px;
    padding: 12px;
    margin-left: 4cm;
}
.cv .contactFoorm .formBxxx .inputBxxx input[type="submit"]:hover{
    background: #c71612;
}
@media (max-width:1200px){
    .c{
        width: 90%;
        min-width: auto;
        margin: 20px;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
    }
    .cv .contactIn{
        top: 0;
        height: 500px;
        position: relative;
        box-shadow: none;
    }
    .cv .contactFoorm{
        position: relative;
        width: calc(100% - 350px);
        padding-left: 0;
        margin-left: 0;
        padding: 40px;
        height: 550px;
        box-shadow: none;
    }
}
@media (max-width:991px){
    .sett{
        background: #fff;
    }
    .sett::before{
        display: none;
    }
    .c{
        display: flex;
        flex-direction: column-reverse;
    }
    .cv .contactFoorm{
        width: 100%;
        height: auto;
    }
    .cv .contactIn{
        width: 100%;
        height: auto;
        flex-direction: row;
    }
}
@media (max-width : 600px){
    .cv .contactFoorm{
        padding: 25px;
    }
    .cv .contactIn{
        padding: 25px;
        flex-direction: column;
        align-items: flex-start;
    }
    .cv .contactFoorm .formBxxx .inputBxxx.w50{
        width: 100%;
    }
}

.msg{
    margin-left: 5.4cm;
    margin-top: -.9cm;
    color: rgb(48, 173, 48);
    display: none;
}
.msgAppear{
    display: block;
}
.he{
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
    position: relative;
    background-size: 100% 100%;
    width:100%;
    height:400px;
    border-top-left-radius:50px; 
    border-top-right-radius:50px; 
    opacity:.9; 
    background-attachment:fixed
}
.vid1{
    height: 100%;
    width: 100%;
    float: left;
    padding: 0;
    position: relative;
    background-position: center;
    z-index: 10;
    position: relative;
    background-size: 100% 100%;
    width:100%;
    height:400px;
    border-top-left-radius:50px; 
    border-top-right-radius:50px; 
    opacity:.9; 
    background-attachment:fixed
}
.naaav{
    margin-top: -89cm !important;
}