.feature{
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
    margin-left: -2cm;
    margin-right: -2cm;
}
.feature-icon{
    position: relative;
    margin-right: 2rem;
}
.inner-icon{
    position: absolute;
    font-size: 20px;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.feature-text h3{
    margin-left: -.2cm;
    font-size: 1.9rem;
    font-weight: 350;
    line-height: 1.2;
    text-transform: uppercase;
    color: rgb(90, 177, 206);
}