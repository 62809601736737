.presentation{
    position: relative;
}
.presentation h4{
    text-align: center;
    font-size: 32px;
    font-weight: bold;
}
.presentation h4 span{
    color: rgba(198,22,18,255);
}
.presentation h5{
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: rgba(198,22,18,255);
}
.presentation h5 span{
    color: black;
}
.presentation .parag{
    text-align: center;
    color: #878e99;
    font-size: 15px;
    line-height: 30px;
    margin-top: 25px;
}
figure{
    overflow: hidden;
    margin: 0 10px;
}
.image img{
    display: block;
    width: 100%;
    height: auto;
    cursor: pointer;
}
.image #zoom-in img{
    transform: scale(1);
    transition: 0.3s ease-in-out;
}
.image #zoom-in:hover img{
    transform: scale(1.5);
}
.homer{
    display: flex;
    background: linear-gradient(to top , #f5f1f1 , #fff);
}

.line {
    width: 18%;
    margin: auto;
    margin-top: 20px;
    border-bottom: 2px dashed #999999;
    position: relative;
  }
.line::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #c61612
    ;
    border-radius: 50%;
    top: -6px;
    left: -20px;
  }
.line::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #c61612
    ;
    border-radius: 50%;
    top: -6px;
    right: -20px;
  }