.back {
    position: absolute;
    right: 8%;
    top: -70px;
  }
  .single-page {
    position: relative;
  }
  
  .main-content p {
    margin: 10px 0 20px 0;
    color: grey;
  }
  .main-content .para p {
    margin-right: 10px;
  }
  .main-content h1 {
    font-size: 30px;
  }
  .main-content img {
    width: 100%;
    height: auto;
  }
  
  article .box {
    background: linear-gradient(145deg , #e2e8ec , #ffffff);
    padding: 20px;
    height: 410px;
    width: 386px;
    color: gray;
  }
  article .box h2 {
    font-size: 30px;
    font-weight: 500;
  }
  article .box2 {
    margin-top: 40px;
    background: url("../../pic/aa.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 180px;
    width: 100%;
    position: relative;
    padding: 20px;
  }
  article .box2::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    height: 180px;
    width: 100%;
  }
  article p {
    font-size: 14px;
    line-height: 25px;
  }
  article .box2 p {
    text-align: center;
    position: relative;
    z-index: 2;
    font-size: 14px;
    line-height: 25px;
    color: white;
  }
  @media screen and (max-width: 768px) {
    .single-page .flex {
      flex-direction: column;
    }
  }
  .imaage{
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    transition: 0.5s;
    width: 100%;
    height: 100%;
  }
  .imaage:hover{
    opacity: .8;
    transform: scale(1.01);
  }
  .header4{
    background-image: url('../../pic/nnnn.jpg');
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
    position: relative;
    background-size: cover;
    width:100%;
    height:400px;
    border-top-left-radius:50px; 
    border-top-right-radius:50px; 
    opacity:.9; 
    background-attachment:fixed
}
.header4 h4{
    font-size: 45px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
}

.header5{
  background-image: url('../../pic/rr.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: 10;
  position: relative;
  background-size: cover;
  width:100%;
  height:400px;
  border-top-left-radius:50px; 
  border-top-right-radius:50px; 
  opacity:.9; 
  background-attachment:fixed
}
.header5 h4{
  font-size: 45px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
}

.header6{
  background-image: url('../../pic/rr.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: 10;
  position: relative;
  background-size: cover;
  width:100%;
  height:400px;
  opacity:.9; 
}
.header6 h4{
  font-size: 45px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
}
.header6 p{
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-left: 3cm;
}
.header6 #io:hover{
  background: transparent !important;
  border: 2px solid #fff !important;
  color: transparent !important;
}
.uii{
  background: #e9eff2 !important;
}