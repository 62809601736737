.question{
    border-radius: 3px;
    width: 90%;
    height: 90%;
    max-width: 60rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.question-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.question-title h4{
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.4;
}
button.question-icon{
    border: 1px solid rgb(233, 233, 233);
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
}

