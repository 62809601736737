.but{
    padding: 12px 18px;
    border-radius: 6px;
    transition: 0.3s all ease;
    cursor: pointer;
    height: fit-content;
    color: rgba(198,22,18,255);
    background: linear-gradient(145deg , #c61612 , #c54644);
    box-shadow: 4px 4px 8px transparent , 4px -4px 8px transparent;
    border: none;
    outline: none; 
    margin-top: .2cm;
}
.but:hover{
    background: linear-gradient(145deg , #970805 , #ac110f);
    transform: translateY(-2px);
}
#cont{
    color: #fff;
}