.title{
    text-align: center;
    margin-bottom: 4rem;
}
.title h5{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-top: .3cm;
}
.title p{
    text-align: center;
    color: #878e99;
    font-size: 17px;
    line-height: 30px;
    margin-top: 10px;
}
.features-content{
    display: flex;
    justify-content: center;
}
.features-left{
    flex: 1;
    text-align: center;
}
.a{
    height: 100vh;
    display: flex;
    align-items: center;
}
.a-left{
    top: -1cm;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    margin-left: -2cm;
}
.a-card{
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}
.a-card.bg{
    position: absolute;
    top: 60px;
    left: 96px;
    background-color:  rgba(198,22,18,255);
    opacity: .7;
}
.a-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.a-img:hover{
    transform: translateY(9px);
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05);
    cursor: zoom-in;
}

.features-right{
    flex: 1;
}
@media screen and (max-width :600px){
    .features-content{
        flex-direction: column;
    }
}
.lines{
    width: 100;
    height: 45px;
    position: relative;
}
.lines::after , .lines::before{
    content: '';
    position: absolute;
    margin: auto;
    height: 2px;
    background: rgb(219, 219, 219);
    width: 45%;
    top: 45%;
}
.lines::after{
    left: 0;
}
.lines::before{
    right: 0;
}
.icone{
    height: 40px;
    width: 40px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}