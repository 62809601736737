.aboutCard h4 {
    color: #c61612;
    align-items: center;
    justify-content: center;
  }
  .aboutCard h1 {
    font-size: 35px;
    font-weight: 100;
  }
  .aboutCard h1 span {
    font-weight: bold;
  }
  .aboutCard p {
    margin-top: 20px;
    font-weight: 300;
  }
  .aboutCard .row1 {
    padding-right: 30px;
  }
  .header2{
    background-image: url('../../pic/rou.jpg');
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
    position: relative;
    background-size: 100% 100%;
    width:100%;
    height:400px;
    border-top-left-radius:50px; 
    border-top-right-radius:50px; 
    opacity:.9; 
    background-attachment:fixed
}