.t{
    margin-top: 50px;
}
.tt{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Lora" , serif;
    color: #444;
}
.ttt{
    position: absolute;
    top: 35%;
    font-size: 60px;
    color: #fff; 
    text-shadow: black 0.05em 0.05em 0.15em
}
.tttt{
    width: 100%;
    height: 500px;
    margin-top: 45.5px;
    object-fit: cover;
}
