.leaflet-container{
    height: 500px;
    width: 100%;
    margin-left: .3cm;
}
#bbtn{
    color: #fff;
    border: 1px solid transparent;
}
#bbtn:focus{
    border: 1px solid transparent;
}
#bbtn:hover{
    background: #fff;
}
#formBlock{
    max-width: 300px;
    background-color: #fff;
    border: 1px solid #ddd;
    position: absolute;
    top: 65.7cm;
    left: 31cm;
    padding: 10px;
    z-index: 999;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 5px;
    width: 100%;
}
.inpuut{
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    border: 1px solid #ddd;
    font-size: 15px;
    border-radius: 3px;
    color: black;
}
#foorm{
    padding: 0;
    margin: 0;
}
.inpuut:nth-child(1){
    margin-bottom: 10px;
}
