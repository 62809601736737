
.coon{
    margin-top: -48.5cm;
    width: 1140px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 4cm;
}
.booxx{
    position: relative;
    width: 300px;
    height: 400px;
    background: #000;
    box-shadow: 0 30px 30px rgba(0, 0, 0, 0.5);
}
.booxx .imgbooxx{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.booxx .imgbooxx img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s;
}
.booxx:hover .imgbooxx img{
    opacity: 0;
}
.booxx .conttent{
    position: absolute;
    bottom: 20px;
    left: 10%;
    width: 80%;
    height: 60px;
    background: #fff;
    transition: .5s;
    overflow: hidden;
    padding: 15px;
    box-sizing: border-box;
}
.booxx:hover .conttent{
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;

}
.booxx .conttent h3{
    margin: 5px;
    padding: 0;
    font-size: 20px;
    color: #c61612;
}
.booxx .conttent p{
    margin: 10px 0 0;
    padding: 0;
    opacity: 0;
    line-height: 1.2em;
    transition: .5s;
    text-align: justify;
}
.booxx:hover .conttent p{
    opacity: 1;
    transition-delay: .4s;
    color: gray;
    line-height: 1.9;
}