section#aide{
    background-image: url('../../pic/oo.jpg');
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: -2;
    text-align: center;
    background-size: 100% 120%;
    background-attachment: fixed;
    color: white;
}
.aide h2{
    font-size: 2rem;
    font-weight: 400;
    line-height: 2;
    text-transform: uppercase;
    margin: 1.5rem 0;
    padding-bottom: 1rem;
}
form > *{
    width: 100%;
    max-width: 50rem;
    margin: 3rem auto;
}
form input{
    padding: 1rem;
    background: transparent;
    border: 1px solid gray;
    border-radius: 5px 0 0 5px;
    color: #fff;    
    outline: none;
    width: 80%;
    font-size: 1rem;
}
form button{
    padding: 1rem;
    background: #fff;
    border-radius: 0 5px 5px 0;
    color: black;
    font-size: 1.05rem;
    font-weight: 500; 
    border: none;
    transition: 0.3s all ease;
}
form button:hover{
    color:#aaa ;
    background: rgba(198,22,18,255);
    border: none;
    transform:translateX(-2px);
    font-size: 1.1rem;
}
form input::placeholder{
    color: #aaa;
}
.social-icons{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.social-icon{
    margin: 2rem;
    padding: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 1px solid #fff;
    font-size: 20px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}
.social-icon:hover{
    background: rgba(198,22,18,255);
}