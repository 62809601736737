.Log{
    width: 220.9vh;
    background-color: #f8f9fe;
    margin-left: -3.35cm;   
}
.Logistique{
    text-align: center;
    padding: 40px 0;
    margin-left: 3.6cm;
}
.Logistique .boox{
    text-align: left;
}
.Logistique img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.Logistique .text{
    background-color: #fff;
    box-shadow: 0 5px 20px 0 rgba(0 0 0 /6%);
    padding: 15px;
    margin: 20px;
    margin-top: -30px;
    position: relative;
    z-index: 1;
}
.Logistique .text h2{
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
}