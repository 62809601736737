.header{
    position: fixed;
    height: 100px;
    width: 100%;
    top: 2cm;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    font-size: 1.02rem;
    line-height: 1.2;
}
.header-bg{
    background-color:  #e9eff2;
    top: 0;
}
.header-bg .nav-item a{
    color: black !important;
}
.header .navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}
.header .navbar img{
    width: 110px;
    height: auto;
}
ul{
    list-style: none;
}
a{
    text-decoration: none;
}
.button{
    padding: 17px 28px;
    font-size: 1.2rem;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #c61612;
    border-radius: 4px;
    cursor: pointer;
}
.button:hover{
    background-color: #aa110e;
}

.header .nav-menu{
    display: flex;
}
.header .nav-item{
    padding: 1rem;
    font-weight: 500;
}
.header .nav-item a:hover{
    padding-bottom: 10px;
    border-bottom: 3px solid #c61612;
}
.header .nav-item a{
    color: #ffff;
}
.hamburger{
    display: none;
}
@media screen and (max-width:940px){
    .header{
        max-width: 100%;
        background-color: rgba(0, 0, 0, .8);
    }
    .header .navbar{
        max-width: 100%;
    }
    .header-bg .nav-item a{
        color: #fff !important;
    }
    .hamburger{
        display: block;
    }
    .nav-menu{
        position: fixed;
        left: -100%;
        top: 165.5px;
        flex-direction: column;
        background-color: rgba(0, 0, 0, .8);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }
    .nav-menu.active{
        left: 0;
    }
    .nav-item{
        margin: 1.5rem 0;
    }
    .navbar img{
        width: 100px;
    }
}
