.header1{
    background-image: url('../../pic/oo.jpg');
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
    position: relative;
    background-size: cover;
    width:100%;
    height:400px;
    border-top-left-radius:50px; 
    border-top-right-radius:50px; 
    opacity:.9; 
    background-attachment:fixed
}
.header1 h4{
    font-size: 45px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
}
