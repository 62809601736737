.heaa{
    text-align: center;
    padding: 2.5rem 0;
}
.heaa span{
    background: #e9eff2;
    color: #c61612;
    font-size: 2rem;
    border-radius: .5rem;
    padding: .2rem 1rem;
}
.heaa span.space{
    background: none;
}
.ser .box-cont{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.ser .box-cont .boxxx{
    flex: 1 1 20rem;
    border-radius: .5rem;
    padding: 1rem;
    text-align: center;
    margin-left: .5cm;
    margin-right: .5cm;
}
.ser .box-cont .boxxx .iic{
    padding: 1rem;
    margin-left: 3.3cm;
    font-size: 4rem;
    color: #c61612;
}
.ser .box-cont .boxxx h3{
    font-size: 1.3rem;
    color: #333;
}
.ser .box-cont .boxxx p{
    font-size: 0.8rem;
    color: #666;
    padding: 1rem 0;
}
.ser .box-cont .boxxx:hover{
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.1);
}